<template>
	<div class="page-container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">{{ pageTitle }}</div>
				<div class="header-button">
					<el-button
						v-permission="'pc/risk-center/Illegal-warning/export'"
						@click="onOpenDialog('export')"
					>
						导出Excel
					</el-button>
				</div>
			</template>
			<template #filter>
				<yi-filter
					ref="filterRef"
					:setting="filterSetting"
					@change="onChangeFilter"
					@single-change="onSingleChange"
				/>
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					show-no
					:columns="columnSetting"
					:data="tableData"
					:params="{ page }"
					@link-click="onOpenDialog"
					@sort-change="onSortChange"
				>
					<!-- <template #action>
						<el-table-column width="240" label="操作" fixed="right">
							<template #default="{ row }">
								<el-link
									type="primary"
									style="margin-right: 15px"
									:underline="false"
									@click="onOpenDialog('detail', row)"
								>
									查看地名
								</el-link>
							</template>
						</el-table-column>
					</template> -->
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
		<form-dialog
			ref="formDialogRef"
			:fuel-type-options="fuelTypeOptions"
			@refresh="onRefreshData"
		/>
	</div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'Weizhangyujing',
})
</script>

<script setup name="Weizhangyujing">
import Request from '@/http'
import { inject, ref, getCurrentInstance, onMounted } from 'vue'
import { filters, columns } from './config'
import FormDialog from './components/Form'
import usePage from '@/hooks/usePage'
import useCompanyOptions from '@/hooks/useCompanyOptions'

const pageTitle = '违章预警'

const instance = getCurrentInstance()

const $message = inject('$message')

const formDialogRef = ref(null)

const filterSetting = ref(filters)
const columnSetting = ref(columns)

const fuelTypeOptions = ref([])
const brandOptions = ref([])
const seriesOptions = ref([])

const {
	isLoading,
	filterParams,
	totalCount,
	page,
	tableData,
	onRefreshData,
	onChangeFilter,
	onSizeChange,
	onPageChange,
	onExport,
	onJumpToPage,
} = usePage(queryList)

function queryList() {
	isLoading.value = true
	const params = {
		...filterParams.value,
		page: page.value,
	}
	Request({
		url: 'suf4-risk-service/trafficIllegalAct/paginate',
		method: 'POST',
		data: params,
	})
		.then(res => {
			if (res.code === 200) {
				isLoading.value = false
				tableData.value = res.data.records
				totalCount.value = res.data.total
				instance.refs.yiTableRef.scrollTop()
				console.log('列表获取成功 ==>', tableData)
			} else {
				isLoading.value = false
				$message.error(res.msg)
			}
		})
		.catch(error => {
			isLoading.value = false
			$message.error(error.message)
		})
}

function onOpenDialog(type, item) {
	switch (type) {
		case 'export':
			{
				isLoading.value = true
				const params = {
					...filterParams.value,
					page: page.value,
				}
				Request({
					url: 'suf4-risk-service/trafficIllegalAct/export',
					method: 'POST',
					data: params,
					responseType: 'blob',
				})
					.then(res => {
						if (res) {
							isLoading.value = false
							console.log('导出结果获取成功 ==>', res)
							onExport('违章预警', res)
						} else {
							isLoading.value = false
							$message.error(res.msg)
						}
					})
					.catch(error => {
						isLoading.value = false
						$message.error(error.message)
					})
			}
			break
		case 'count':
			{
				console.log('检测到链接点击 ==>', item)
				const to = {
					code: 'violation-management',
					name: '违章明细',
					path: '/violation-management',
					source: 'user',
				}
				onJumpToPage(to, {
					keyword: item.carNumber,
				})
			}
			break
	}
}
function onSortChange(row) {
	console.log('row', row)
	const { order, prop } = row
	if (prop === 'totalAmount') {
		filterParams.value.isUntreatedFineDesc = order === 'descending'
		filterParams.value.isUntreatedScoreDesc = null
	}
	if (prop === 'totalDeduct') {
		filterParams.value.isUntreatedScoreDesc = order === 'descending'
		filterParams.value.isUntreatedFineDesc = null
	}
	queryList()
}

function onSingleChange(e) {
	if (e.attr === 'brandId') {
		console.log('品牌选项变更 ==>', e, brandOptions.value)
		const selectedBrandItem = brandOptions.value.find(option => option.id === e.value)
		console.log('找到的车系列表 ==>', selectedBrandItem)
		if (selectedBrandItem) {
			seriesOptions.value = selectedBrandItem.children
			const filterIndex = filterSetting.value.filters.findIndex(item => {
				return item.attr === 'seriesId'
			})
			filterSetting.value.filters[filterIndex].options = seriesOptions.value.map(option => {
				return {
					code: option.id,
					name: option.name,
				}
			})
		}
	}
}

function initPage() {
	useCompanyOptions(filterSetting)
	onRefreshData()
}

onMounted(async () => {
	initPage()
})
</script>

<style lang="less" scoped>
.page-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.icon-plus {
		margin-right: 5px;
	}
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
}
</style>
